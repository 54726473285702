import React from 'react'
import { useState } from 'react'; 
import {
    Text,
    Input,
    InputGroup,
    InputLeftAddon,
    InputLeftElement

  } from '@chakra-ui/react';


export const OutPutSteps = ({steps}) => {
  
  // const [inst,addStep ] = useState([    ])
  // addStep([...inst,steps])
  console.log(steps)
  
  return (
    
  //  steps.map((item) => {

  //   <Text>{item}</Text>

  //  })
  <>
  {steps}
  
  </>
   )
}

export default OutPutSteps