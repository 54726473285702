import React from 'react'
import { useState } from 'react'; 
import {
    Text,
    Input,
    InputGroup,
    InputLeftAddon,
    InputLeftElement,
    Button

  } from '@chakra-ui/react';



export const CarParameters = ({ carinputs }) => {

  
 
  return (
    <>
    
    {carinputs}

    </>

    
  )
}

export default CarParameters