import React from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Input,
  InputGroup,
} from '@chakra-ui/react';

export const AnotherCar = ({carid}) => {
  return (
    <Box key={"car_"+carid} id={"Car_"+carid} name="carbox" p={3}>
    <InputGroup key={"carname_"+carid} isrequired='true'>
          <Input key={"carname_input"+carid} name='carname' id={"carname_"+carid} type='text' placeholder='driver of car' />
    </InputGroup>
    <InputGroup key={"carseats_"+carid}  isrequired='true'>
          <Input key={"carseats_input"+carid} name='seats' id={"carseats_"+carid} type='number' placeholder='# of seats available' />
    </InputGroup>
    <InputGroup key={"carbikespots_"+carid} isrequired='true'>
           <Input key={"carbikespots_input"+carid}  name='bikespots' id={"carbikespots_"+carid}  type='number' placeholder='# of bikes it can carry' />
    </InputGroup>
    </Box>
  )
}
