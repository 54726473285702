import {
  Text,
  Box,
  Center
} from '@chakra-ui/react';
export const Title = () => {
  return (
    <Box flexDirection="row">
      
      <Center>
        <Text display="flex" fontSize='4xl'>ShuttleBitch!</Text> 
        
        <Text  display="flex" fontSize="smaller" fontWeight="light">beta</Text>
        </Center>
     </Box>
  )
}

export default Title