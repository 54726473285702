import {
  Text,
  Divider,
  Box

} from '@chakra-ui/react';
export const Footer = () => {
  return (

  <Box position="fixed" left="90%" right="0" bottom="0">
    <Divider/>
    <Text  fontSize='xs' fontWeight='light' textAlign="center">Because Math is hard, and bikes rule</Text> 
  </Box>
  )
}

export default Footer