import React from 'react';
import { useState } from 'react'; 
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  Center,
  theme,
  Input,
  InputGroup,
  List,
  Divider,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import { Logo } from './Logo';
import Title from './components/Title';
import Footer from './components/Footer'
import { Button, ButtonGroup } from '@chakra-ui/react'
import { RideParameters } from './components/RideParameters';
import { CarParameters } from './components/CarParameters';
import { AnotherCar } from './components/AnotherCar';
import { OutPutSteps } from './components/OutPutSteps';
import { Step } from './components/Step';
import { plotShuttle, car } from './shuttleb/shuttleb.js'

var carCount = 1;

function App() {
  const [carinputs,addCars ] = useState([<AnotherCar key={"carid_container_"+ 0}carid="0"/>,<AnotherCar key={"carid_container_"+1}carid={1}/>])
  const [steps,addStep ] = useState([])






function removeCar () {

  addCars([...carinputs.slice(0,-1)])
  carCount-- //just to give a 
}



function setCarInput () {
    carCount++ //just to give a 
    addCars([...carinputs,<AnotherCar key={"carid_container_"+ carCount}carid={carCount}/>])
    

  }

function getInputsAndGo() {
//Gather all the input from the page, and send it the algorhythm
  var cars = [];
  var totalcars = document.getElementsByName("carname").length; // check and see how many carname elements exist 
  for (let i = 0; i < totalcars; i++){  

    let seats = document.getElementById("carseats_"+i).value;
    let bikeSpots = document.getElementById("carbikespots_"+i).value;
    let carName = document.getElementById("carname_"+i).value;
    cars[i] = new car(seats, bikeSpots, carName);
    console.log(cars[i]);


            //all cars need a name
            if (!(carName)) {
              alert("All vehicles must have a name")
              return []
            } 

    

    //must be a positive integer for bike spots
    if (!/^\d+$/.test(parseInt(bikeSpots))) {
      alert("Bike Spots must be a positive number for " + carName)
      return []
    } 

     //must be a positive integer for bike spots
     if (!/^\d+$/.test(parseInt(seats))) {
      alert("Car seats must be a positive number for " + carName)
      return []
    }    
    
  }

  var riders_parsed = parseInt(document.getElementById("bikesandriders").value);
  var bikes_parsed = parseInt(document.getElementById("bikesandriders").value);

  console.log(riders_parsed);
  console.log(bikes_parsed);

      //must be a positive integer for bike spots
      if (!/^\d+$/.test(parseInt(riders_parsed))) {
        alert("You must enter a real number of riders/bikes")
        return []
      } 

  const instructions = plotShuttle(cars, riders_parsed, bikes_parsed)



  let stepid=0
  let newArray = [] //had to use a new array because "...steps" didn't update inside the map for some reason
  const map = instructions.map((item) => {
    newArray.push(<Step key={stepid}test={item}stepid={stepid}/>)
    stepid++
  }
  )
  addStep([newArray])
  // addStep([...steps,instructions])
  return ""


}
// var i = 0
// var list = []

  return (
    <ChakraProvider theme={theme}>
      <Box p={3} textAlign="center" fontSize="xl">
        <Title />
        <Grid  p={3}>
          {/* <ColorModeSwitcher justifySelf="flex-end" /> */}
          <RideParameters />
          <Text fontSize='xl'>Then, enter some cars</Text> 
          <Box id="carInputs" name="carInputs">
          <CarParameters carinputs={carinputs} />
          </Box>
          <Box>
          <Button marginRight="10%" colorScheme='blue' onClick={setCarInput}> Add Another Car</Button>
          <Button marginLeft="10%" colorScheme='blue' onClick={removeCar}> Remove Last Car</Button>
          </Box>
        </Grid>

      </Box>
  
      <Button colorScheme='blue' marginright="10%" marginLeft="10%" width="80%" onClick={getInputsAndGo}> Do the Math!</Button>
            
      
      <Center>
      <Text fontSize="2xl" textAlign="left">Shuttle Instructions will appear below: </Text>
    
      {/* <Box py="2" px="2" borderColor="blue" colorScheme='blue' border="2px" borderRadius="md" textAlign="justify" id="output">
        
      </Box> */}
      </Center>
      <Divider />
      <Center>
        <Box marginBottom="5%">
      <List id="rideOutputs" name="rideOutputs">
          <OutPutSteps steps={steps} />
      </List>
      </Box>
      </Center>
      <Footer />
    </ChakraProvider>
  );
}

export default App;

