import React from 'react'
import { useState } from 'react'; 
import {
    Text,
    Input,
    InputGroup,
    InputLeftAddon,
    InputLeftElement,
    Box,
    List,
    ListItem

  } from '@chakra-ui/react';

  

export const Step = ({
test, stepid}) => {

  return (
          <ListItem key={"step_"+stepid} name='step' id={"step_"+stepid} type='text'>{test}</ListItem>
  )
}

export default Step