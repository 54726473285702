import React from 'react'
import {
    Text,
    Input,
    InputGroup,
    InputLeftAddon,
    InputLeftElement

  } from '@chakra-ui/react';

export const RideParameters = () => {
  return (
  <div>
  <Text>It's real simple, enter the amount of people you need to shuttle and the bikes</Text>
  <InputGroup isrequired='true'>
    <Input name='riders' id='bikesandriders' type='number' placeholder='# of bikes and riders' />
  </InputGroup>
  {/* <InputGroup isrequired='true'>
    <Input name='bikes' id='bikes' type='number' placeholder='# of bikes' />
  </InputGroup> */}
  </div>
  )
}
