import React from 'react';

let output = [];

class car {
    //The class that uniquely defines a car and it's characteristics
    // static incrementCarCount() {
    //     carCount++ ;
    // }


    constructor(seats,bikeSpots,name){
    this.seats = seats;
    this.bikeSpots = bikeSpots;
    this.name = name;
    this.filledSeats = 0;
    this.filledBikeSpots = 0;
    this.location = "origin" ; // origin or trailhead
    this.id = Math.round(Math.random() * 1000)
    this.transits = 0 // how many point-to-point transits a car has done
    this.freeSeats = this.seats;
    this.freeBikeSpots = this.bikeSpots;
    // car.incrementCarCount()

    }

    shuttle() {
        //go from Origin->Trailhead 
        console.log(this.name + " shuttling! with " + this.filledSeats + " Riders and " + this.filledBikeSpots + " Bikes!");
        this.location = "trailhead"
        this.transits++
    }
    

    returnToOrigin(){
        //go from Trailhead->Origin
        console.log(this.name + " returning to origin! with " + this.filledSeats + " Riders and " + this.filledBikeSpots + " Bikes!");
        this.location = "origin"
        this.transits++
    }

    calculateFreeSlots(){
        this.freeSeats = this.seats - this.filledSeats;
        this.freeBikeSpots = this.bikeSpots - this.filledBikeSpots;

    }
}

class ride {

    logStatus(){

console.log(this.nonriders + " Nonriders in group");
console.log(this.ridersAtOrigin + " Riders at Origin");
console.log(this.bikesAtOrigin + " Bikes at Origin");
console.log(this.ridersAtTrailHead + " Riders at Trail Head");
console.log(this.bikesAtTrailHead + " Bikes at Trailhead");
console.log(this.carsAtOrigin + " Cars at Origin");
console.log(this.carsAtTrailHead + " Cars at Trailhead");
console.log(this.totalBikeSpots + " Overall Bike Capacity");
console.log(this.totalSeats + " Overall Human Capacity");
console.log("-------");
}

    //The class that defines the "ride"
    constructor(riders,bikes,cars,nonriders){
        this.ridersAtOrigin = riders;
        this.bikesAtOrigin = bikes;
        this.nonriders = nonriders;
        this.cars = cars.sort(function(a,b){return b.bikeSpots - a.bikeSpots})
        this.carCount = cars.length;
        this.carsAtOrigin = this.carCount;
        this.carsAtTrailHead = 0;
        this.totalSeats = 0;
        this.totalBikeSpots = 0;
        this.bikesAtTrailHead = 0;
        this.ridersAtTrailHead = 0;
        this.flag = 0;
        

        this.cars.forEach(element => {
            console.log(element.name);
            this.totalSeats = this.totalSeats + parseInt(element.seats);
            this.totalBikeSpots = this.totalBikeSpots + parseInt(element.bikeSpots);
            
        })

        this.logStatus();


    }

    checkCarLocation(){

this.carsAtOrigin = 0;
this.carsAtTrailHead = 0;
for (let x in this.cars) {
    console.log(this.cars[x])
    if (this.cars[x].location === "origin")
    {
        this.carsAtOrigin++;
    }
    else{
        //implicit that it's at the trailhead in current ver, as 2 locations only
        this.carsAtTrailHead++;

    }

    }

}

    loadCar(car){

        car.calculateFreeSlots();

        this.checkCarLocation();


        //load up as many riders as have free seats and bikes
        //But you can't load a car

        if (car.location === "origin" && this.ridersAtOrigin !== 0){
            if (car.freeSeats >= this.ridersAtOrigin)
            {
                car.filledSeats += this.ridersAtOrigin;
                console.log(car.name + " loaded " + this.ridersAtOrigin + " riders");
                this.ridersAtOrigin = 0;
                
            }
                else{
                        car.filledSeats += car.freeSeats;
                        this.ridersAtOrigin -= car.freeSeats;
                        console.log(car.name + " loaded " + car.freeSeats + " riders");
                    }
            if (car.freeBikeSpots >= this.bikesAtOrigin)
            {
                car.filledBikeSpots += this.bikesAtOrigin;
                console.log(car.name + " loaded " + this.bikesAtOrigin + " bikes");
                this.bikesAtOrigin = 0;
                if(this.bikesAtOrigin !== 0 && this.ridersAtOrigin === 0){
                    //actually you can't leave the bikes alone at the origin, so ditch a person
                  //  document.getElementById("output").innerHTML += "<p>1 person stays behind and guards the bikes while shuttling </p>"
                    output.push("1 person stays behind and guards the bikes while shuttling")
                    car.filledSeats -= 1;
                    this.ridersAtOrigin += 1;
                }
       
            }
                else{
                    car.filledBikeSpots += car.freeBikeSpots;
                    this.bikesAtOrigin -= car.filledBikeSpots;
                    console.log(car.name + " loaded " + car.freeBikeSpots + " bikes");
                    if(this.bikesAtOrigin !== 0 && this.ridersAtOrigin === 0){
                        //actually you can't leave the bikes alone at the origin, so ditch a person
                       // document.getElementById("output").innerHTML += "<p>1 person stays behind and guards the bikes while shuttling </p>"
                        output.push("1 person stays behind and guards the bikes while shuttling")
                        car.filledSeats -= 1;
                        this.ridersAtOrigin += 1;
                    }
                }
            //now that the car is loaded, let's test to see if there is any bad conditions
            
        }
        if (car.location === "trailhead")//the logic you do if it'sd a return trip, because it's "not origin"
        {
            car.filledSeats = 1; 
            this.ridersAtTrailHead -= 1;
            console.log(car.name + " loaded " + " 1 person to return it to origin");
             
        }        
    
        //console.log("meow" + car.filledSeats);
        //console.log(car.name + " Loaded");
        console.log(car.name + " has " + car.filledSeats + " Seats Filled and " + (car.seats - car.filledSeats) + " Seats free");
        console.log(car.name + " has " + car.filledBikeSpots + " Bike Spots Filled and " + (car.bikeSpots - car.filledBikeSpots) + " Bike Spots free");

    if (car.location === "origin"){
   if(this.ridersAtOrigin < 0){
         this.ridersAtOrigin = 0;
    }
   if(this.bikesAtOrigin < 0){
       this.bikesAtOrigin = 0;
   }
}
    }

    unLoadCar(car){

        this.checkCarLocation();
        car.calculateFreeSlots();
        if (car.location === "origin"){ //if for some reason you wanted to unload at the origin? allow it
            this.ridersAtOrigin += car.filledSeats;
            this.bikesAtOrigin += car.filledBikeSpots;
            console.log(car.name + " unloaded " + car.filledBikeSpots + " Bikes and " + car.filledSeats + " riders at the origin");
        }
        if (car.location === "trailhead"){
            this.ridersAtTrailHead += car.filledSeats;
            this.bikesAtTrailHead += car.filledBikeSpots;
            console.log(car.name + " unloaded " + car.filledBikeSpots + " Bikes and " + car.filledSeats + " riders at the trailhead");
        }

        car.filledSeats = 0;
        car.filledBikeSpots = 0;
        car.calculateFreeSlots();


    }


        

}

function plotShuttle(cars, riders, bikes){

    output = []

    // document.getElementById('output').innerHTML = "" //reset the output area to blank

        // var cars = [];
        // cars[0] = new car (2,2,"The Dank Tank");
        // cars[1] = new car (5,4,"The Adventure Mobile");
        // cars[2] = new car (2,2,"The Jason Wagon");
        // cars[3] = new car (2,2,"The Cinnamon Swirl");
        // cars[3] = new car (2,2,"The Grand Tusk");
var carCount = cars.length;

if (carCount === 1) {
//Launch Shuttle bug Algorithm. It's kinda simple.
//document.getElementById("output").innerHTML = "It's kind of simple. One person is going to shuttle everyone until everyone is at the top. This noble soul will then wait for everyone at the bottom.";
output.push("It's kind of simple. One person is going to shuttle everyone until everyone is at the top. This noble soul will then wait for everyone at the bottom.")
console.log(output)
return output

}

//input validation, must be a postive integer
//todo , make error more react native instead of an alert
if (!/^\d+$/.test(riders)) {
    alert('Please enter a valid number for riders and bikes');
    return [];
  }


var plan = new ride (parseInt(riders), parseInt(bikes), cars, 0)

// var plan = new ride(30,30,cars,0); //riders, bikes, car objects array, non-riders


//First see if one car ride is enough. Probably the most common real life usecase
if (plan.ridersAtOrigin <= plan.cars[0].seats && plan.bikesAtOrigin <= plan.cars[0].bikeSpots)  {
    
    output.push("Everyone can get in with " + plan.cars[0].name + " and go to the top. Leave one at the bottom for a fetch")
    //document.getElementById("output").innerHTML = "Everyone can get in with " + plan.cars[0].name + " and go to the top. Leave one at the bottom for a fetch";
    plan.loadCar(plan.cars[0]);
    //plan.logStatus();
    plan.cars[0].shuttle();
    //plan.logStatus();
    plan.unLoadCar(plan.cars[0]);
    plan.logStatus();
    console.log(output)
    return output //end code execution
}

//If you can't fit everyone in one car, you need to determine if it will be more effecient to do a second shuttle with one car,


var firstcar = cars[0];
var fetchcar = cars[(cars.length -1) ]

// //document.getElementById("output").innerHTML += "<p> " + plan.ridersAtOrigin + " Riders " + plan.bikesAtOrigin + " Bikes " + " and " + plan.nonriders + " nonriders";
// plan.cars.forEach(element => {

// document.getElementById("output").innerHTML += "<p> " + element.name + " with " + element.seats + " seats and " + element.bikeSpots + " bike spots available";
// }
// )

while(plan.bikesAtOrigin !== 0 || plan.ridersAtOrigin !== 0){
    
plan.cars.forEach(element => {

            //document.getElementById("output").innerHTML += "<p> " + plan.ridersAtOrigin + " Riders at origin " + plan.bikesAtOrigin + " Bikes at origin " + " and " + plan.nonriders + " nonriders";
                //document.getElementById("output").innerHTML += "<p> " + plan.ridersAtTrailHead + " Riders at trailhead " + plan.bikesAtTrailHead + " Bikes trailhead " + " and " + plan.nonriders + " nonriders";
            //grab the 'best' car then see where we are
            //leaves the last car in the picking order there no matter what. Sends other cars there
            //The idea is keep sending cars until there is one left, then have one do allez-retour

            //test to see if we are done first

            if ((plan.ridersAtOrigin === 0) && (plan.bikesAtOrigin === 0)){
                if (plan.carsAtOrigin > 1){
                    console.log(output)
                    return output
                }
            }
            console.log("Cars_at_origin: " + plan.carsAtOrigin)
            if (plan.carsAtOrigin > 1){
                if (element.location==="origin"){
                plan.loadCar(element);
                output.push(""+ element.filledSeats + " people get in with " + element.name + " with " + element.filledBikeSpots + " bikes and go to the trailhead.")
              //  document.getElementById("output").innerHTML += "<p>"+ element.filledSeats + " people get in with " + element.name + " with " + element.filledBikeSpots + " bikes and go to the trailhead.</p>";
                element.shuttle();
                plan.unLoadCar(element);
                plan.logStatus();
                console.log(output)
                return output
                }
            }
            else{
                    if((plan.bikesAtOrigin - firstcar.seats) > 0 && (plan.ridersAtOrigin - firstcar.bikeSpots) > 0 )
                    {
                        if((plan.bikesAtOrigin - (firstcar.bikeSpots * 2 )) > 0 || (plan.ridersAtOrigin - (firstcar.seats * 2)) > 0 ){
                                //If there's gonna be a lot of trips, start sending cars back down
                                console.log('ok')
                                if (element.location === "trailhead"){
                                plan.loadCar(element);
                                output.push(element.name + " returns to the origin with 1 driver after unloading all bikes")
                              //  document.getElementById("output").innerHTML += "<p>" + element.name + " returns to the origin with 1 driver after unloading all bikes</p>"
                                element.returnToOrigin();
                                console.log(output)
                                return output
                                }
                                // plan.cars.forEach(element =>{
                                //     if (element.location === "trailhead"){
                                //     plan.loadCar(element);
                                //     document.getElementById("output").innerHTML += "<p>" + element.name + " returns to the origin with 1 draaaiver after unloading all bikes</p>"
                                //     element.returnToOrigin();
                                //    }
                                   
                                //       }   )
                            

                        }
                        //in the above circumstance where the firstcar would need to do two runs to get all stragglers, we can dispatch the leftover car to help
                        if (element.location === "origin"){
                        plan.loadCar(element);
                        output.push(element.filledSeats +" people get in with " + element.name + " with " + element.filledBikeSpots + " bikes and go to the trailhead")
                       // document.getElementById("output").innerHTML += "<p>"+ element.filledSeats + " people get in with " + element.name + " with " + element.filledBikeSpots + " bikes and go to the trailhead.</p>";
                        element.shuttle();
                        plan.unLoadCar(element);
                        plan.logStatus();
                        plan.loadCar(element);
                        element.returnToOrigin();
                        output.push(element.name + " returns to the origin with 1 driver after unloading all bikes")
                       // document.getElementById("output").innerHTML += "<p>" + element.name + " returns to the origin with 1 driver after unloading all bikes</p>"
                        plan.unLoadCar(element); //Gotta leave the car at the bottom!
                        plan.logStatus();
                        console.log(output)
                        return output
                    }

                    }
                    
                    //if at this point there are either more bikes or cares than the first car can take, we him back some help
                    if ( (plan.ridersAtOrigin >= (firstcar.totalSeats -1)) || (plan.bikesAtOrigin >= firstcar.bikeSpots)){
                        if( element.location === "trailhead"){
                            plan.loadCar(element); //load the car with only the driver to send back to origin
                            console.log("moo")
                            plan.logStatus();
                            element.returnToOrigin();
                            output.push(element.name + " returns to the origin with 1 driver after unloading all bikes")
                          //  document.getElementById("output").innerHTML += "<p>" + element.name + " returns to the origin with 1 driver after unloading all bikes</p>"
                            plan.loadCar(element);
                            element.shuttle();
                            output.push(element.filledSeats + " people get in with " + element.name + " with " + element.filledBikeSpots + " bikes and go to the trailhead.")
                          //  document.getElementById("output").innerHTML += "<p>"+ element.filledSeats + " people get in with " + element.name + " with " + element.filledBikeSpots + " bikes and go to the trailhead.</p>";
                            plan.unLoadCar(element);
                            plan.logStatus();
                            console.log(output)
                            return output    
                        }

                    }
                    //Check to see if we're done here
                    if ((plan.ridersAtOrigin === 0) && (plan.bikesAtOrigin === 0)){
                        console.log(output)
                            return output

                    }

                    //if we get to here, just see whose at the origin and who can shuttle

                    if (element.location === "origin"){

                    if ((plan.ridersAtOrigin <= 1 && plan.carsAtOrigin === 1)){
                        console.log(output)
                        return output
                }
                    
                        plan.loadCar(element);
                        console.log("meoow")
                        plan.logStatus();
                        element.shuttle();
                        output.push(element.filledSeats + " people get in with " + element.name + " with " + element.filledBikeSpots + " bikes and go to the trailhead.")
                    //    document.getElementById("output").innerHTML += "<p>"+ element.filledSeats + " people get in with" + element.name + " with " + element.filledBikeSpots + " bikes and go to the trailhead.</p>";
                        plan.unLoadCar(element);
                        plan.loadCar(element);
                        element.returnToOrigin();
                        output.push(element.name + " returns to the origin with 1 driver after unloading all bikes")
                    //    document.getElementById("output").innerHTML += "<p>" + element.name + " returns to the origin with 1 driver after unloading all bikes</p>"
                        plan.unLoadCar(element);
                        plan.logStatus();
                        console.log(output)
                        return output
                        
                     }else{
                     plan.loadCar(element); //load the car with only the driver to send back to origin
                     console.log("meoow")
                     plan.logStatus();
                     element.returnToOrigin();
                     plan.unLoadCar(element);
                     output.push(element.name + " returns to the origin with 1 driver after unloading all bikes")
                   //  document.getElementById("output").innerHTML += "<p>" + element.name + " returns to the origin with 1 driver after unloading all bikes</p>"
                     plan.loadCar(element);
                     element.shuttle();
                     output.push(element.filledSeats + " people get in with " + element.name + " with " + element.filledBikeSpots + " bikes and go to the trailhead.")
                //     document.getElementById("output").innerHTML += "<p>"+ element.filledSeats + " people get in with " + element.name + " with " + element.filledBikeSpots + " bikes and go to the trailhead.</p>";
                     plan.unLoadCar(element);
                     plan.logStatus();
                     console.log(output)
                     return output
                     
                  }
                //     if (firstcar.location === "trailhead"){

                    
                //     plan.loadCar(firstcar); //load the car with only the driver to send back to origin
                //     console.log("meoow")
                //     plan.logStatus();
                //     firstcar.returnToOrigin();
                //     document.getElementById("output").innerHTML += "<p>" + firstcar.name + " returns to the origin with 1 driver after unloading all bikes</p>"
                //     plan.loadCar(firstcar);
                //     firstcar.shuttle();
                //     document.getElementById("output").innerHTML += "<p>"+ firstcar.filledSeats + " people get in with " + firstcar.name + " with " + firstcar.filledBikeSpots + " bikes and go to the trailhead.</p>";
                //     plan.unLoadCar(firstcar);
                //     plan.logStatus();
                //     return output
                    
                //  }

                    }

            }
            
        
            //We assume you always need a fetch, so one car must always be at the bottom.

        )
        
        
        //just hack it, if everyone is up and so all ar 
        if ((plan.ridersAtOrigin === 0) && (plan.bikesAtOrigin === 0)){
            if (fetchcar.location === "trailhead"){
                if (plan.carsAtOrigin === 0){
                plan.loadCar(fetchcar);
                output.push(fetchcar.name + " Is the retreive car and goes back down with 1 person as the driver")
             //   document.getElementById("output").innerHTML += "<p>" + fetchcar.name + " Is the retreive car and goes back down with 1 person as the driver</p>"
                fetchcar.returnToOrigin();
                plan.unLoadCar(fetchcar);
                plan.logStatus();
            }
    
            }          
            
    }



    console.log('ok')
    }
    
   
    plan.cars.forEach(element => {

        if (element.location === "origin"){
            output.push(element.name + " Is a retreive car and waits at the origin point")
           // document.getElementById("output").innerHTML += "<p>" + element.name + " Is a retreive car and waits at the origin point</p>"

        }

    })   
    return output
        }
        

        export {plotShuttle, car, ride}